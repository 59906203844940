body {
  font-family: 'Arial', sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f8f9fa; /* Light gray background */
  color: black; /* Default text color */
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-content {
  display: flex;
  flex: 1;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  background-color: white; /* White background */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.navbar-brand {
  font-size: 1.8em; /* Larger font for brand */
  font-weight: bold;
  color: #007cba; /* Docker blue */
}

.navbar-links {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
}

.navbar-links li {
  margin-left: 30px; /* Increased spacing */
  position: relative; /* For dropdown positioning */
}

.navbar-links a {
  color: black; /* Black text for links */
  text-decoration: none;
  font-weight: 600; /* Bold links */
}

.navbar-links a:hover {
  text-decoration: underline;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background: white; /* White background for dropdown */
  border: 1px solid #e0e0e0;
  z-index: 1000;
  min-width: 150px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  display: none; /* Hide by default */
}

.dropdown:hover .dropdown-menu {
  display: block; /* Show on hover */
}

.nav-button {
  background-color: transparent;
  border: 1px solid #007cba;
  color: #007cba;
  padding: 8px 15px; /* More padding for a button-like appearance */
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.signup-button {
  background-color: #007cba;
  color: white; /* White text for signup button */
  padding: 8px 15px; /* Consistent padding */
  border-radius: 5px; /* Rounded corners */
}

.signup-button:hover {
  background-color: #005f8a; /* Darker blue on hover */
}

.nav-button:hover {
  background-color: rgba(0, 124, 186, 0.1); /* Light blue on hover */
}

.sidebar {
  width: 220px;
  background-color: #f8f9fa; /* Light gray background for sidebar */
  padding: 20px;
  border-right: 1px solid #e0e0e0;
}

.sidebar h3 {
  margin-top: 0;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  margin: 15px 0; /* More space between items */
}

.sidebar ul li a {
  text-decoration: none;
  color: #007cba; /* Docker blue */
}

.sidebar ul li a:hover {
  text-decoration: underline;
}

footer {
  background-color: #007cba; /* Docker blue */
  color: white; /* White text for footer */
  text-align: center;
  padding: 15px 0;
  margin-top: auto;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

main {
  padding: 20px;
  flex: 1;
}
