.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px;
    background-color: white; /* White background */
    color: black; /* Black text */
    border-bottom: 1px solid #e0e0e0; /* Optional: border for separation */
  }
  
  .navbar-brand {
    font-size: 1.5em;
    font-weight: bold;
  }
  
  .navbar-links {
    list-style: none;
    padding: 0;
    display: flex;
    align-items: center;
  }
  
  .navbar-links li {
    margin-left: 20px;
    position: relative; /* For dropdown positioning */
  }
  
  .navbar-links a {
    color: black; /* Black text */
    text-decoration: none;
  }
  
  .navbar-links a:hover {
    text-decoration: underline;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    border: 1px solid #e0e0e0;
    z-index: 1000;
    min-width: 150px;
    display: none; /* Hide by default */
  }
  
  .dropdown:hover .dropdown-menu {
    display: block; /* Show on hover */
  }
  
  .nav-button {
    background-color: transparent;
    border: 1px solid #007cba;
    color: #007cba;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .signup-button {
    background-color: #007cba;
    color: white;
  }
  
  .signup-button:hover {
    background-color: #005f8a;
  }
  
  .nav-button:hover {
    background-color: #f0f0f0;
  }
  